import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/en'
import 'dayjs/locale/ja'
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(timezone)
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

// set timezone to Ho Chi Minh
dayjs.tz.setDefault(import.meta.env.VITE_APP_TIMEZONE)

// set locale to ja
dayjs.locale('ja')

const DateTimeHelper = {
  formatTime(time, format) {
    if (!time) {
      return '--:--'
    }

    if (!format) {
      format = 'HH:mm'
    }

    const values = time.split(':')
    if (values[0] !== '' && values[0] !== undefined && values[0] !== null && values[0] !== 'undefined') {
      values[0] = parseInt(values[0]) || 0
    } else {
      // set to current hour
      values[0] = dayjs().hour()
    }

    if (!values[1]) {
      // set to 00
      values[1] = '00'
    } else {
      values[1] = parseInt(values[1]) || '00'
      values[1] = parseInt(values[1]).toString().padStart(2, '0')
    }

    // add date to time to current date
    const current = dayjs()
    const date = current.format('YYYY-MM-DD')
    return dayjs(`${date} ${values[0]}:${values[1]}`).format(format)
  },
  isSameOrBefore(start, end) {
    return dayjs(start, 'HH:mm').isSameOrBefore(dayjs(end, 'HH:mm'))
  },
  isSameOrAfter(start, end) {
    return dayjs(start, 'HH:mm').isSameOrAfter(dayjs(end, 'HH:mm'))
  },
  format(date, format) {
    if (!date) {
      return ''
    }

    if (date.length === 10) {
      date = `${date} 23:59:59`
    }

    if (!format) {
      format = 'YYYY-MM-DD'
    }

    return dayjs(date).format(format)
  },
  formatToVi(date) {
    return dayjs(date).locale('vi').format('YYYY-MM-DD')
  },
  formatCalendar(date) {
    // format date to HH:mm, if mm is 00, return HH:時
    const dt = dayjs(date)
    if (dt.minute() === 0) {
      return dt.format('HH時')
    }

    return dt.format('HH:mm')
  },
  convert2JpDate(date) {
    const dt = dayjs(date, "YYYY-MM-DD").isValid() ? dayjs(date, "YYYY-MM-DD") : false
    return dt ? dayjs(dt).format('YYYY年MM月DD日') : date;
  },
  // compare local time: today vs date
  isBeforeToday(date) {
    return dayjs(date).isBefore(dayjs())
  },
  getToday() {
    // get in vi locale
    return dayjs().format('YYYY-MM-DD')
  },
  isToday(date) {
    return dayjs(date).isSame(dayjs(), 'day')
  },
  convertToDateWithDayOfWeek(date) {
    return dayjs(date).locale('ja').format('YYYY年MM月DD日(ddd)')
  },
  addDays(date, days) {
    // days can be negative or positive
    return dayjs(date).add(days, 'day').format('YYYY-MM-DD')
  },
  // count days of month
  getDaysOfMonth(date) {
    return dayjs(date).daysInMonth()
  },
  // get current time with format 15:14
  getCurrentDate() {
    return dayjs().format('YYYY-MM-DD')
  },
  getCurrentTime() {
    return dayjs().format('HH:mm')
  },
  getCurrentTimeEnd() {
    return dayjs().add(2, 'hour').format('HH:mm')
  },
  getLastDayOfMonth(date) {
    return dayjs(date).endOf('month').format('YYYY-MM-DD')
  },
  getDayOfWeekShort(date) {
    return dayjs(date).locale('ja').format('ddd')
  },
  getJapaneseWeekday(dateString) {
    const date = dayjs(dateString);
    return date.format('dddd');
  },
  getDaysInMonth: function (value) {
    let daysInMonth = ''
    if (value && (value.year == undefined || value.month == undefined)) {
      daysInMonth = dayjs().daysInMonth()
    }
    else {
      daysInMonth = dayjs(`${value.year}-${parseInt(value.month) + 1}-01`).daysInMonth()
    }    
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1)
    return days
  },
  getDayOfWeek: function (yearmonth, day) {
    return dayjs(`${yearmonth.year}-${parseInt(yearmonth.month) + 1}-${day}`).format('DD ddd')
  },
  formatDatetime: function (datetime, format) {
    return dayjs(datetime).format(format).toString()
  },

  formatTimeObject: function (datetime) {
    return { "hours": dayjs(datetime).get('hour'), "minutes": dayjs(datetime).get('minute')}
  },
  isTelescopicallyOverlapped: function (start1, end1, start2, end2) {
    const s1 = dayjs(start1, 'HH:mm')
    const e1 = dayjs(end1, 'HH:mm')
    const s2 = dayjs(start2, 'HH:mm')
    const e2 = dayjs(end2, 'HH:mm')
    return (s1.isBefore(e2) && e2.isBefore(e1)) || (s2.isBefore(e1) && e1.isBefore(e2))
  },
  dtIsSameOrBefore: function (start, end) {
    return dayjs(start).isSameOrBefore(dayjs(end))
  }
}

export default DateTimeHelper
