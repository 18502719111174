import { defineStore } from 'pinia'

export const useClubManagementStore = defineStore('club-management', {
  state: () => ({
    clubs: [],
    types: [
      { value: '', text: 'タイプ' },
      { value: 'guest_related', text: '学校関係者' },
      { value: 'guest', text: 'ゲスト' },
    ],
    attributes: [
      { value: '', text: '属性' },
      { value: 'student', text: '生徒' },
      { value: 'father', text: '保護者(父)' },
      { value: 'mother', text: '保護者(母)' },
      { value: 'children', text: '未就学児' },
      { value: 'relatives', text: '在籍生徒の親族' },
      { value: 'teacher', text: '教員' },
      // { value: 'staff', text: 'スタッフ' },
    ],
    filter: {
      club: 0, // クラブ‐すべて = { 0, club-id}
      type: '', // タイプ = { '', '学校関係者', 'ゲスト'}
      attribute: '', // 属性 = { '', 生徒, 保護者(父), 保護者(母), 未就学児, 在籍生徒の親族, 教員, スタッフ }
      text: '', // 名前、電話番号で検索
    },
    items: [],
    total: 0,
    page: 1,
    lastPage: 1,
  }),
  actions: {
    getClubs: async function() {
      const response = await axios.get('/api/club-management/clubs');
      if (response.status !== 200) {
        this.errors.response = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        return
      }
      this.clubs = response.data.data;
    },
    fetch: async function() {
      const response = await axios.get('/api/club-management', { params: { ...this.filter, page: this.page } });
      if (response.status !== 200) {
        this.errors.response = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        return
      }
      // remove key, just keep values of object, loop for all children in array
      this.items = response.data.data;
      this.total = response.data.total;
      this.lastPage = response.data.last_page;
    },
    getTypeName: function(value) {
      return this.types.find(type => type.value === value)?.text;
    },
  },
})