import { defineStore } from 'pinia'

export const useTimeAble = defineStore('timeable', {
  state: () => ({
    item: {
      user_id: 0,
      type: '',
      attribute: '',
      name: '',
      memo: '',
      start_time: '',
      end_time: '',
    }
  }),
  actions: {
    add: async function() {
      if (!this.item.user_id || !this.item.start_time || !this.item.end_time) {
        return
      }

      return await axios.post('/api/timeable', this.item)
    },
    reset: function() {
      this.item = {
        user_id: 0,
        type: '',
        attribute: '',
        name: '',
        memo: '',
        start_time: '',
        end_time: '',
      }
    },
  },
})