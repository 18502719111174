<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import TabHoliday from './TabHoliday.vue'
import TabBaisc from './TabBaisc.vue';
import TabTimeable from './TabTimeable.vue';

const selectedTab = ref(0)
const changeTab = (index) => {
  selectedTab.value = index
}
</script>
<template>
<div class="flex flex-col h-full w-full p-4">
<TabGroup :selectedIndex="selectedTab" @change="changeTab">
  <TabList class="w-full items-center border-b-2 border-b-primary">
    <Tab class="w-1/3 px-4 py-1 focus:border-none appearance-none focus:ring-0 focus:outline-none transition delay-75 duration-300 ease-in-out" 
      :class="{ 
        'bg-primary text-white': selectedTab == 0,
        'bg-graysp': selectedTab != 0
      }"
    >ベトナムの祝日設定</Tab>
    <Tab class="w-1/3 px-4 py-1 focus:border-none appearance-none focus:ring-0 focus:outline-none transition delay-75 duration-300 ease-in-out"
      :class="{ 
        'bg-primary text-white': selectedTab == 1,
        'bg-graysp': selectedTab != 1
      }"
    >施設・物品・基本時間設定</Tab>
    <Tab class="w-1/3 px-4 py-1 focus:border-none appearance-none focus:ring-0 focus:outline-none transition delay-75 duration-300 ease-in-out"
      :class="{ 
        'bg-primary text-white': selectedTab == 2,
        'bg-graysp': selectedTab != 2
      }"
    >下校時間設定</Tab>
  </TabList>
  <TabPanels>
    <TabPanel class="transition delay-75 duration-300 ease-in-out">
      <TabHoliday />
    </TabPanel>
    <TabPanel class="transition delay-75 duration-300 ease-in-out">
      <TabBaisc />
    </TabPanel>
    <TabPanel class="transition delay-75 duration-300 ease-in-out">
      <TabTimeable />
    </TabPanel>
  </TabPanels>
</TabGroup>
</div>
</template>
