import LoginPage from '../../views/auth/Login.vue';
import DashboardPage from '../../views/pages/Dashboard.vue';
import AccoutPage from '../../views/pages/Account.vue';
import ImportLogIndex from '../../views/pages/import/Index.vue';
import ImportLogPage from '../../views/pages/import/List.vue';
import ImportLogDetailPage from '../../views/pages/import/_Id.vue';
import EntrancePage from '../../views/pages/entrance/Index.vue';
import EntranceDetailPage from '../../views/pages/entrance/Detail.vue';
import EntranceReportPage from '../../views/pages/entrance/Report.vue';
import CardGuestPage from '../../views/pages/cardguest/Index.vue';
import StaffPage from '../../views/pages/staff/Index.vue';
import DatabaseIndexPage from '../../views/pages/database/Index.vue';
import DatabaseListPage from '../../views/pages/database/List.vue';
import DatabaseIdPage from '../../views/pages/database/_Id.vue';
import EmailSendPage from '../../views/pages/EmailSend.vue';
import SettingIndexPage from '../../views/pages/setting/Index.vue';
import SettingEmailPage from '../../views/pages/setting/EmailConfig.vue';
import SettingEmailTemplatePage from '../../views/pages/setting/EmailTemplate.vue';
import EmailHistoryPage from '../../views/pages/EmailHistory.vue';
import ClubPage from '../../views/pages/club/Club.vue';
import ClubEditPage from '../../views/pages/club/Edit.vue';
import ResourcePage from '../../views/pages/resource/Index.vue';
import CalendarPage from '../../views/pages/calendar/Index.vue';
// import RequestResourcePage from '../../views/pages/request/Index.vue';
import ClubRequestResourcePage from '../../views/pages/club-request/Index.vue';
import EventHistoryPage from '../../views/pages/event/Index.vue';
import EventGroupPage from '../../views/pages/event/EventGroup.vue';
import EventEmailTemplatePage from '../../views/pages/event/EmailTemplate.vue';
import EventEmailApprovalTemplatePage from '../../views/pages/event/EmailApprovalTemplate.vue';
import EventEmailConfigPage from '../../views/pages/event/EmailConfig.vue';
import FacilityPage from '../../views/pages/facility/Index.vue';
import ApprovalPage from '../../views/pages/approval/Index.vue';
import ClubManagementPage from '../../views/pages/club-management/Index.vue';

// view front end
import UndoukaiPage from '../../views/pages/front-end/undoukai/Index.vue';
import FrontendIndexPage from '../../views/pages/front-end/Index.vue';
import ClubRegistration from '../../views/pages/front-end/club/Index.vue';
import ClubRegistrationForm1 from '../../views/pages/front-end/club/Form1.vue';
import ClubRegistrationForm2 from '../../views/pages/front-end/club/Form2.vue';
import ClubRegistrationForm3 from '../../views/pages/front-end/club/Form3.vue';
import ClubRegistrationForm4 from '../../views/pages/front-end/club/Form4.vue';
import NotFound from '../../views/pages/front-end/404.vue';

import APPJSH from '../helper/ConstantHelper';

const routes = [
  // Auth
  {
    path: '/login',
    component: LoginPage,
  },
  // Dashboard
  {
    path: '/',
    component: DashboardPage,
    meta: {
      title: 'ダッシュボード',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.PTA, APPJSH.ROLES.CLUB],
    }
  },
  // Account
  {
    path: '/account',
    component: AccoutPage,
    meta: {
      title: 'アカウント管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  // Import log
  {
    path: '/import',
    component: ImportLogIndex,
    meta: {
      title: 'データインポート記録',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    },
    children: [
      {
        path: '',
        component: ImportLogPage,
        meta: {
          title: 'データインポート記録',
          roles: [APPJSH.ROLES.SCHOOL],
        }
      },
      {
        path: ':id',
        component: ImportLogDetailPage,
        meta: {
          title: 'データインポート記録',
          roles: [APPJSH.ROLES.SCHOOL],
        }
      }
    ]
  },
  // Entrance
  {
    path: '/entrance',
    component: EntrancePage,
    meta: {
      title: '入出校管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  {
    path: '/entrance/detail',
    component: EntranceDetailPage,
    meta: {
      title: '入出校管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  {
    path: '/entrance/report',
    component: EntranceReportPage,
    meta: {
      title: '報告',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  // Staff manager
  {
    path: '/staff',
    component: StaffPage,
    meta: {
      title: '職員管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  // card guest
  {
    path: '/card-guest',
    component: CardGuestPage,
    meta: {
      title: 'ゲスト管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  // Database
  {
    path: '/database',
    component: DatabaseIndexPage,
    meta: {
      title: 'データベース',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    },
    children: [
      {
        path: '',
        component: DatabaseListPage,
        meta: {
          title: 'データベース',
          roles: [APPJSH.ROLES.SCHOOL],
        }
      },
      {
        path: ':id',
        component: DatabaseIdPage,
        meta: {
          title: '生徒情報',
          roles: [APPJSH.ROLES.SCHOOL],
        }
      }
    ]
  },
  {
    path: '/send-email',
    component: EmailSendPage,
    meta: {
      title: 'メール配信',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.PTA],
    }
  },
  // Email History
  {
    path: '/email-history',
    component: EmailHistoryPage,
    meta: {
      title: 'メール配信履歴',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.PTA],
    },
  },
  // Setting
  {
    path: '/setting',
    component: SettingIndexPage,
    meta: {
      title: '設定',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.PTA],
    },
    children: [
      {
        path: 'email-config',
        component: SettingEmailPage,
        meta: {
          title: 'メール送信設定',
          roles: [APPJSH.ROLES.SCHOOL],
        }
      },
      {
        path: 'email-template',
        component: SettingEmailTemplatePage,
        meta: {
          title: 'テンプレート設定',
          roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.PTA],
        }
      }
    ]
  },
  {
    path: '/club',
    component: ClubPage,
    meta: {
      title: 'クラブ一覧',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.CLUB],
    }
  },
  {
    path: '/club/:id',
    component: ClubEditPage,
    meta: {
      title: 'クラブ管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.CLUB],
    }
  },
  {
    path: '/resources',
    component: ResourcePage,
    meta: {
      title: '予定表関連設定',
      titleBody: 'スケジュール関連設定',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  {
    path: '/facility',
    component: FacilityPage,
    meta: {
      title: '施設利用予定表',
      titleBody: '施設利用予定表',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  {
    path: '/event-calendar',
    component: CalendarPage,
    meta: {
      title: '学校行事予定表',
      titleBody: '学校行事予定表',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  {
    path: '/request-resource',
    component: ClubRequestResourcePage,
    meta: {
      title: '施設利用',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL, APPJSH.ROLES.CLUB],
    }
  },
  {
    path: '/event-history',
    component: EventHistoryPage,
    meta: {
      title: 'イベントチケット',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  {
    path: '/event-group',
    component: EventGroupPage,
    meta: {
      title: 'グループ管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  {
    path: '/event/email-template',
    component: EventEmailTemplatePage,
    meta: {
      title: 'テンプレート設定',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  {
    path: '/event/email-approval-template',
    component: EventEmailApprovalTemplatePage,
    meta: {
      title: '承認メール設定',
      help: '※承認後に承認状況を変更した場合クラブ代表のメールアドレス宛にメールで通達をする。',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  {
    path: '/event/email-config',
    component: EventEmailConfigPage,
    meta: {
      title: 'メール設定',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  // クラブ総合管理
  {
    path: '/club-management',
    component: ClubManagementPage,
    meta: {
      title: 'クラブ総合管理',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  // front-end
  {
    path: '/front-end',
    component: FrontendIndexPage,
    meta: {
      title: '404',
      forPublic: true,
    },
    children: [
      {
        path: 'undoukai',
        component: UndoukaiPage,
        meta: {
          title: '運動会',
          forPublic: true,
        }
      },
      {
        path: 'undoukai/:person',
        component: UndoukaiPage,
        meta: {
          title: '運動会',
          forPublic: true,
        }
      },
      {
        path: 'club-membership/:id',
        component: ClubRegistration,
        meta: {
          title: 'クラブメンバー登録form',
          forPublic: true,
        }
      },
      {
        path: 'club-membership/:id/1',
        component: ClubRegistrationForm1,
        meta: {
          title: 'クラブメンバー登録form',
          forPublic: true,
        }
      },
      {
        path: 'club-membership/:id/2',
        component: ClubRegistrationForm2,
        meta: {
          title: 'クラブメンバー登録form',
          forPublic: true,
        }
      },
      {
        path: 'club-membership/:id/3',
        component: ClubRegistrationForm3,
        meta: {
          title: 'クラブメンバー登録form',
          forPublic: true,
        }
      },
      {
        path: 'club-membership/:id/4',
        component: ClubRegistrationForm4,
        meta: {
          title: 'クラブメンバー登録form',
          forPublic: true,
        }
      },
      {
        path: ':pathMatch(.*)*',
        component: NotFound,
        meta: { forPublic: true }
      },
    ]
  },
  // 施設利用申請管理
  {
    path: '/approval',
    component: ApprovalPage,
    meta: {
      title: '施設利用申請管理',
      help: '', //'※同施設で時間が重複している予定があります。未承認の予定があります。月を跨ぐと未承認の予定は申請却下状態となります。',
      requiresAuth: true,
      roles: [APPJSH.ROLES.SCHOOL],
    }
  },
  // exception
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
    meta: {
      requiresAuth: true,
    }
  }
];

export default routes;
