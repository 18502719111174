<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { CalendarIcon } from '@heroicons/vue/24/outline'
import { useTimeAble } from '../../../js/store/timeable';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import DateTimeHelper from '../../../js/helper/DatetimeHelper';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const timeAble = useTimeAble()

const isOpen = ref(false)
const open = () => {
  isOpen.value = true
}
const close = () => {
  timeAble.reset()
  isOpen.value = false
}

const save = async () => {
  if (!timeAble.item.start_time || !timeAble.item.end_time) {
    $root.$push.error('開始日時と終了日時は必須です')
    return
  }

  if (!DateTimeHelper.dtIsSameOrBefore(timeAble.item.start_time, timeAble.item.end_time)) {
    $root.$push.error('終了日時は開始日時より後に設定してください')
    return
  }

  $root.overlay.loading = true
  await timeAble.add()
  $root.overlay.loading = false

  $root.push.success('設定しました')

  close()
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-primary"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900 flex items-center gap-2 py-2 px-4 bg-graysp justify-between"
              >
                学校訪問設定
              </DialogTitle>
              <div class="flex flex-col gap-2 p-6">
                <h3 class="mb-4 font-semibold text-md p-2 rounded bg-gray-100 flex items-center justify-between">
                  {{ timeAble.item.name }}
                  <span class="text-xs text-gray-400">{{ timeAble.item.attribute }}</span>
                </h3>
                <div class="flex flex-col">
                  備考
                  <div>
                    <textarea type="text" class="rounded border border-gray-200 px-2 py-1 w-full" v-model="timeAble.item.memo" rows="2" />
                  </div>
                </div>
                <div class="flex flex-col md:flex-row gap-2">
                  <div class="flex flex-col">
                    <div class="flex items-start gap-2">
                      開始日時
                      <span class="text-error">*</span>
                    </div>
                    <div class="max-w-48">
                      <VueDatePicker 
                        v-model="timeAble.item.start_time" 
                        locale="ja"
                        format="yyyy-MM-dd HH:mm"
                        auto-apply
                        teleport
                      >
                        <template #input-icon>
                          <CalendarIcon class="ml-2 w-4 h-4" />
                        </template>
                        <template #dp-input="{ value }">
                          <input 
                            type="text" 
                            :value="value" 
                            class="border border-gray-200 rounded pl-8 pr-2 py-1 max-w-44"
                            placeholder="----/--/--- --:--"
                          />
                        </template>
                      </VueDatePicker>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <div class="flex items-start gap-2">
                      終了日時
                      <span class="text-error">*</span>
                    </div>
                    <div class="max-w-48">
                      <VueDatePicker 
                        v-model="timeAble.item.end_time" 
                        locale="ja"
                        format="yyyy-MM-dd HH:mm"
                        auto-apply
                        teleport
                      >
                        <template #input-icon>
                          <CalendarIcon class="ml-2 w-4 h-4" />
                        </template>
                        <template #dp-input="{ value }">
                          <input 
                            type="text" 
                            :value="value" 
                            class="border border-gray-200 rounded pl-8 pr-2 py-1 max-w-44"
                            placeholder="----/--/--- --:--"
                          />
                        </template>
                      </VueDatePicker>
                    </div>
                  </div>
                </div>
                <div class="flex items-center gap-4 mt-4 justify-center">
                  <button class="px-4 py-1.5 bg-primary text-white font-semibold rounded hover:opacity-80" @click.prevent="save">登録する</button>
                  <button class="px-4 py-1.5 border rounded font-semibold hover:opacity-80" @click.prevent="close">キャンセル</button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>