<script setup>
import { ref, onMounted, getCurrentInstance  } from 'vue';
import { useClubManagementStore } from '../../../js/store/club-management';
import { useClubStore } from '../../../js/store/club';
import { useMember } from '../../../js/store/member';
import { MagnifyingGlassIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import Pagination from './Pagination.vue';
import EditTabMemberEditGuestModal from '../club/member/EditTabMemberEditGuestModal.vue'
import SchoolAccessSettingModal from './SchoolAccessSettingModal.vue'
import { useTimeAble } from '../../../js/store/timeable';

const clubStore = useClubStore();
const memberStore = useMember();
const timeAble = useTimeAble();
const clubManagementStore = useClubManagementStore();
const $root = getCurrentInstance()?.appContext.config.globalProperties;

const refEditTabMemberEditGuestModal = ref(null)
const refSchoolAccessSettingModal = ref(null)

onMounted(async () => {
  clubManagementStore.getClubs();
  fetchData();
});

const fetchData = async () => {
  $root.overlay.loading = true
  clubManagementStore.page = 1;
  await clubManagementStore.fetch();
  $root.overlay.loading = false
};

const showEditModal = async (id, clubId, type) => {
  clubStore.member.id = id

  $root.overlay.loading = true
  await memberStore.getItem(id, clubId, type)
  $root.overlay.loading = false
  
  refEditTabMemberEditGuestModal.value.open();
}

const addTimeable = async (member) => {
  timeAble.item.user_id = member.id
  timeAble.item.name = member.name
  timeAble.item.type = member.type
  timeAble.item.attribute = member.attribute

  refSchoolAccessSettingModal.value.open();
}

</script>
<template>
  <div class="flex flex-col w-full gap-2 p-4">
    <!-- filter -->
    <div class="flex items-center gap-2 flex-wrap">
      総数:
      <span>{{ clubManagementStore.total }}件</span>
      <div class="w-fit">
        <select class="rounded border border-gray-200 px-2 py-1 bg-white" v-model="clubManagementStore.filter.club">
          <option value="0">クラブ‐すべて</option>
          <option v-for="club in clubManagementStore.clubs" :value="club.id">{{ club.name }}</option>
        </select>
      </div>
      <div class="w-fit">
        <select v-model="clubManagementStore.filter.type" class="rounded border border-gray-200 px-2 py-1 bg-white">
          <option v-for="type in clubManagementStore.types" :value="type.value">
            {{ type.text }}
          </option>
        </select>
      </div>
      <div class="w-fit">
        <select v-model="clubManagementStore.filter.attribute" class="rounded border border-gray-200 px-2 py-1 bg-white">
          <option v-for="attribute in clubManagementStore.attributes" :value="attribute.value">
            {{ attribute.text }}
          </option>
        </select>
      </div>
      <div class="w-52 relative">
        <input type="text" v-model="clubManagementStore.filter.text" class="rounded border border-gray-200 pl-2 pr-8 py-1 w-full" placeholder="名前、電話番号で検索">
        <XMarkIcon v-show="clubManagementStore.filter.text" class="w-5 h-5 absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400 transition-all delay-75 duration-300 ease-in-out" @click.prevent="clubManagementStore.filter.text = ''" />
      </div>
      <div>
        <button @click.prevent="fetchData" class="rounded bg-primary text-white px-4 py-1.5 flex items-center gap-1 hover:opacity-80">
          <MagnifyingGlassIcon class="w-5 h-5" />
          検索
        </button>
      </div>
    </div>

    <!-- table -->
    <div class="w-full">
      <table class="w-full divide-y divide-gray-200">
        <thead class="bg-gray-100 border-t-2 border-t-primary">
          <tr>
            <th class="font-normal w-16 text-center">Note</th>
            <th class="font-normal text-left">名前</th>
            <th class="font-normal text-left">所属クラブ</th>
            <th class="font-normal text-left">タイプ</th>
            <th class="font-normal text-left">属性</th>
            <th class="font-normal"></th>
          </tr>
        </thead>
        <tbody v-if="clubManagementStore.items.length">
          <template v-for="item in clubManagementStore.items">
            <tr v-for="(member, idx) in item" 
              :class="{
                'border-b border-b-gray-200': idx < item.length - 1,
                'border-b-2 border-b-gray-400': idx === item.length - 1,
                'bg-red-200': !member.kyc
              }"
            >
              <td class="p-1 text-center w-fit">
                <div v-if="!member.kyc" class="flex items-center text-error text-center justify-center gap-1">
                  <ExclamationTriangleIcon class="w-4 h-4 text-error" />
                  KYC
                </div>
              </td>
              <td class="p-1">{{ member.name }}</td>
              <td class="p-1">
                <div class="flex flex-col">
                  <div v-for="club in member.clubs">{{ club }}</div>
                </div>
              </td>
              <td class="p-1 w-36">
                {{ clubManagementStore.getTypeName(member.type) }}
              </td>
              <td class="p-1 w-48">
                {{ member.attribute }}
              </td>
              <td class="p-1 w-44">
                <div v-if="member.type != 'staff'" class="flex items-center justify-center gap-1">
                  <button class="px-2 py-0.5 rounded bg-green text-white font-semibold hover:opacity-80" @click.prevent="addTimeable(member)">訪問設定</button>
                  <button class="px-2 py-0.5 rounded bg-primary text-white font-semibold hover:opacity-80" @click.prevent="showEditModal(member.id, member.club_id, member.type)">編集</button>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="p-4 text-center" colspan="6">データがありません</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- pagination -->
    <Pagination />

    <EditTabMemberEditGuestModal ref="refEditTabMemberEditGuestModal" :id="clubStore.member.id || 0" />
    <SchoolAccessSettingModal ref="refSchoolAccessSettingModal" />
  </div>
</template>