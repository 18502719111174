<script setup>
import { getCurrentInstance  } from 'vue';
import { useClubManagementStore } from '../../../js/store/club-management';
import { ChevronDoubleLeftIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline';

const clubManagementStore = useClubManagementStore();
const $root = getCurrentInstance()?.appContext.config.globalProperties;

const goPage = async (page) => {
  clubManagementStore.page = page;
  if (clubManagementStore.page < 1) {
    clubManagementStore.page = 1;
  }
  if (clubManagementStore.page > clubManagementStore.lastPage) {
    clubManagementStore.page = clubManagementStore.lastPage;
  }

  $root.overlay.loading = true
  await clubManagementStore.fetch();
  $root.overlay.loading = false
};
</script>
<template>
  <div v-if="clubManagementStore.lastPage > 1" class="flex items-center gap-1 justify-between">
    <div class="text-gray-400">
      <!-- <span>{{ clubManagementStore.page }} / {{ clubManagementStore.lastPage }}</span> -->
    </div>
    <div class="flex items-center gap-1">
      <button class="p-1.5 rounded hover:opacity-80"
        :class="{
          'bg-primary text-white': clubManagementStore.page > 1,
          'bg-gray-200 text-gray-400': clubManagementStore.page === 1
        }"
        :disabled="clubManagementStore.page === 1"
        @click.prevent="goPage(1)"
      >
        <ChevronDoubleLeftIcon class="w-5 h-5" />
      </button>
      <button class="p-1.5 rounded hover:opacity-80"
        :class="{
          'bg-primary text-white': clubManagementStore.page > 1,
          'bg-gray-200 text-gray-400': clubManagementStore.page === 1
        }"
        :disabled="clubManagementStore.page === 1"
        @click.prevent="goPage(clubManagementStore.page - 1)"
      >
        <ChevronLeftIcon class="w-5 h-5" />
      </button>
      <button class="p-1.5 rounded hover:opacity-80"
        :class="{
          'bg-gray-200 text-gray-400': clubManagementStore.page === clubManagementStore.lastPage,
          'bg-primary text-white': clubManagementStore.page < clubManagementStore.lastPage
        }"
        :disabled="clubManagementStore.page === clubManagementStore.lastPage"
        @click.prevent="goPage(clubManagementStore.page + 1)"
      >
        <ChevronLeftIcon class="w-5 h-5 rotate-180" />
      </button>
      <button class="p-1.5 rounded hover:opacity-80"
        :class="{
          'bg-primary text-white': clubManagementStore.page < clubManagementStore.lastPage,
          'bg-gray-200 text-gray-400': clubManagementStore.page === clubManagementStore.lastPage
        }"
        :disabled="clubManagementStore.page === clubManagementStore.lastPage"
        @click.prevent="goPage(clubManagementStore.lastPage)"
      >
        <ChevronDoubleLeftIcon class="w-5 h-5 rotate-180" />
      </button>
    </div>
  </div>
</template>